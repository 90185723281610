import { motion } from "framer-motion"
import './App.css';
import React, {
	useState,
	useEffect,
	useRef
} from "react"

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const getBackgroundSize = (value) => {
  return { backgroundSize: `${(value * 100) / 1000}% 100%` };
};

const formatForDisplay = (number, includeDecimals) => {
  return number.toString().padStart(4, "0")
    .split("")
    .reverse();
};

const DecimalColumn = ({ fontSize, color }) => {
  return (
    <div>
      <span
        style={{
          fontSize: fontSize,
          lineHeight: fontSize,
          color: color
        }}
      >
        .
      </span>
    </div>
  );
};

const NumberColumn = ({
  digit,
  delta,
  fontSize,
  color,
  incrementColor,
  decrementColor
}) => {
  const [position, setPosition] = useState(0);
  const [animationClass, setAnimationClass] = useState(null);
  const previousDigit = usePrevious(digit);
  const columnContainer = useRef();

  const setColumnToNumber = (number) => {
    setPosition(columnContainer.current.clientHeight * parseInt(number, 10));
  };

  useEffect(() => setAnimationClass(previousDigit !== digit ? delta : ""), [
    digit,
    delta
  ]);



  useEffect(() => setColumnToNumber(digit), [digit]);

  return (
    <div
      className="ticker-column-container"
      ref={columnContainer}
      style={{
        fontSize: fontSize,
        lineHeight: fontSize,
        color: color,
        height: "auto",
        "--increment-color": incrementColor,
        "--decrement-color": decrementColor
      }}
    >
      <motion.div
        animate={{ x: 0, y: position }}
        className={`ticker-column ${animationClass}`}
        onAnimationComplete={() => setAnimationClass("")}
      >
        {[9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((num) => (
          <div key={num} className="ticker-digit">
            <span
              style={{
                fontSize: fontSize,
                lineHeight: fontSize,
                color: color,
              }}
            >
              {num}
            </span>
          </div>
        ))}
      </motion.div>
      <span className="number-placeholder">0000</span>
    </div>
  );
};

// Counter component
const AnimatedCounter = ({
  value = 0,
  fontSize = "18px",
  color = "white",
  incrementColor = "#32cd32",
  decrementColor = "#fe6862",
  includeDecimals = false
}) => {
  const numArray = formatForDisplay(value, includeDecimals);
  const previousNumber = usePrevious(value);

  let delta = null;
  if (value > previousNumber) delta = "increase";
  if (value < previousNumber) delta = "decrease";

  return (
    <motion.div layout className="ticker-view">
      {numArray.map((number, index) =>
        <NumberColumn
        key={index}
        digit={number}
        delta={delta}
        fontSize={fontSize}
        incrementColor={incrementColor}
        decrementColor={decrementColor}
        includeDecimals={includeDecimals}
      />
      )}
    </motion.div>
  );
};

// Main app component
const App = () => {
  const [counterValue, setCounterValue] = useState(0);

  useEffect(()=>{
    document.addEventListener("keydown", keyPress, false);
    return ()=>{
      document.removeEventListener("keydown", keyPress, false);
    }
  })
  
  const keyPress = (e) =>{ 
    if (e.key === " ") { 
      let cnt = counterValue + 1
      setCounterValue(cnt)
    }
  }

  const reset = () =>{
    setCounterValue(0)
  }
  return (
    <div className="App">
      <div className="frame">
        <div className="inner">
          <div className="block">
            <div className="seg"></div>
            <div className="seg"></div>
            <div className="seg"></div>
            <div className="seg"></div>
          </div>
          <AnimatedCounter value={counterValue} fontSize='31vh'/>
        </div>
      </div>
      <div className="jumbotron"></div>
      <div onClick={reset} className="left-reset"></div>
      <div onClick={reset} className="right-reset"></div>
    </div>
  );
};

export default App;
